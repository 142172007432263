<template>
  <div>
    <skelet-rectangle :width="1920" :height="400" />

    <div class="container">
      <skelet-rectangle :width="1920" :height="50" />
      <skelet-rectangle v-for="item in 3" :key="item" :width="1920" :height="300" />
    </div>
  </div>
</template>

<script>
import SkeletRectangle from "@/views/sceleton/components/rectangle";

export default {
  name: "tournamentPage-skeleton",
  components: {
    SkeletRectangle
  }
}
</script>
