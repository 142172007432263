<template>
  <div class="mt64" v-if="!IS_TOURNAMENTS_IN_REQUEST">
    <div class="tournamentHeader"
         :style="{backgroundImage: `url(${TOURNAMENT.background})`}"
         :class="[TOURNAMENT.attributes.sponsorship ? 'approved' : null]"
    >
      <div class="container">
        <div class="th-info">
          <div class="th-left">
            <!--            {{ :class="[TOURNAMENT.openedRegistration ? 'opened' : '']"}}-->
            <div class="th-title" :class="[isRegOpen(TOURNAMENT) ? 'opened' : '']">
              {{ TOURNAMENT.attributes.name }}
            </div>
            <div
                v-if="isSponsor(TOURNAMENT)"
            >
              <span class="s1">Партнёр:</span>
              <span class="s2">{{ TOURNAMENT.attributes.partner }}</span>
            </div>
          </div>
          <div class="th-right">
            <!--<div class="th-share">
              <span>поделится</span>
              &lt;!&ndash;<div class="th-share_svg">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H24V24H0V0Z" fill="#3A3A3A"/>
                  <path opacity="0.6"
                        d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                        fill="white"/>
                  <path opacity="0.6"
                        d="M12 7C12.5523 7 13 6.55228 13 6C13 5.44772 12.5523 5 12 5C11.4477 5 11 5.44772 11 6C11 6.55228 11.4477 7 12 7Z"
                        fill="white"/>
                  <path opacity="0.6"
                        d="M12 19C12.5523 19 13 18.5523 13 18C13 17.4477 12.5523 17 12 17C11.4477 17 11 17.4477 11 18C11 18.5523 11.4477 19 12 19Z"
                        fill="white"/>
                  <path
                      d="M0 0V-1H-1V0H0ZM24 0H25V-1H24V0ZM24 24V25H25V24H24ZM0 24H-1V25H0V24ZM0 1H24V-1H0V1ZM23 0V24H25V0H23ZM24 23H0V25H24V23ZM1 24V0H-1V24H1Z"
                      fill="white" fill-opacity="0.1"/>
                </svg>
              </div>&ndash;&gt;
            </div>-->
            <!--<button class="th-track" @click="openTrack = !openTrack">
              Отслеживать турнир
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 12L10 8L6 4" stroke="white" stroke-linejoin="round"/>
              </svg>
            </button>
            <form class="track-form"
                  @submit.prevent=""
                  :class="toggleActive">
              <div class="title">Получать уведомления о:</div>
              <div class="check-group">
                <label class="square-checkbox labeled">
                  <input type="checkbox">
                  <div class="svg-container"></div>
                  <div class="check-string__text">Завершении регистрации</div>
                </label>
                <label class="square-checkbox labeled">
                  <input type="checkbox">
                  <div class="svg-container"></div>
                  <div class="check-string__text">Начале турнира</div>
                </label>
                <label class="square-checkbox labeled">
                  <input type="checkbox">
                  <div class="svg-container"></div>
                  <div class="check-string__text">Финальных матчах</div>
                </label>
                <label class="square-checkbox labeled">
                  <input type="checkbox">
                  <div class="svg-container"></div>
                  <div class="check-string__text">Начале следущей стадии</div>
                </label>
                <label class="square-checkbox labeled">
                  <input type="checkbox">
                  <div class="svg-container"></div>
                  <div class="check-string__text">Завершении турнира</div>
                </label>
              </div>
              <div class="title">Куда присылать:</div>
              <div class="check-group">
                <label class="square-checkbox labeled">
                  <input type="checkbox">
                  <div class="svg-container"></div>
                  <div class="check-string__text">Email</div>
                </label>
                <label class="square-checkbox labeled">
                  <input type="checkbox">
                  <div class="svg-container"></div>
                  <div class="check-string__text">Телефон</div>
                </label>
              </div>
              <button type="submit" class="filled__yellow">Отслеживать турнир</button>
            </form>-->
          </div>
          <media :query="{maxWidth: 768}">
            <div class="th-wrapper">
              <div class="content-teams">
                <div class="teams-label">
                  <svg width="32" height="32" viewBox="0 0 120 120">
                    <circle cx="60" cy="60" r="54" fill="none" stroke="#B0B0B0" stroke-width="9"/>
                    <circle class="content-label__fill" cx="60" cy="60" r="54" fill="none" stroke="#E2FF31"
                            stroke-width="9"
                            stroke-dasharray="339.292"
                            stroke-dashoffset="135.717"/>
                  </svg>
                  <svg class="s2" width="18" height="13" viewBox="0 0 18 13" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.95002 0C4.08018 0 3.37502 0.809647 3.37502 1.80838C3.37502 2.80713 4.08018 3.61674 4.95002 3.61674C5.81987 3.61674 6.52502 2.80713 6.52502 1.80838C6.52502 0.809647 5.81987 0 4.95002 0ZM9.00002 0C7.88165 0 6.97502 0.910829 6.97502 2.03443C6.97502 3.15802 7.88165 4.06884 9.00002 4.06884C10.1184 4.06884 11.025 3.15802 11.025 2.03443C11.025 0.910829 10.1184 0 9.00002 0ZM13.05 0C12.1802 0 11.475 0.809647 11.475 1.80838C11.475 2.80713 12.1802 3.61674 13.05 3.61674C13.9199 3.61674 14.625 2.80713 14.625 1.80838C14.625 0.809647 13.9199 0 13.05 0ZM3.84612 4.05471C1.82112 4.6056 0.900024 4.79018 0.900024 8.82995L2.00393 9.38092C2.37211 7.7283 2.37531 7.36065 2.5594 7.36065C2.74349 7.36065 2.73966 7.912 3.10784 9.74827C3.27137 9.77544 3.44373 9.79975 3.62112 9.81889C3.68998 7.93373 3.96549 6.79078 4.56331 6.01849C5.25927 5.11953 6.28619 4.87677 7.5094 4.54215C7.10899 4.34401 6.62683 4.21057 6.05393 4.05471C5.31757 4.6056 4.58248 4.6056 3.84612 4.05471ZM11.9461 4.05471C11.3671 4.21223 10.8794 4.33879 10.4766 4.53506C11.7066 4.87195 12.7404 5.12112 13.4367 6.05381C14.0131 6.82599 14.2915 7.96537 14.3719 9.81889C14.5517 9.79977 14.7266 9.77578 14.8922 9.74827C15.2604 7.912 15.2566 7.36065 15.4406 7.36065C15.6247 7.36065 15.6279 7.7283 15.9961 9.38092L17.1 9.01362C17.1 4.79024 16.1789 4.6056 14.1539 4.05471C13.4176 4.6056 12.6825 4.6056 11.9461 4.05471ZM7.65002 4.973C5.17502 5.65115 4.05002 5.87718 4.05002 10.8502L5.40002 11.5283C5.85002 9.49391 5.85002 9.04183 6.07502 9.04183C6.30002 9.04183 6.30002 9.71999 6.75002 11.9805C8.10002 12.2065 9.90002 12.2065 11.25 11.9805C11.7 9.71999 11.7 9.04183 11.925 9.04183C12.15 9.04183 12.15 9.49391 12.6 11.5283L13.95 11.0763C13.95 5.87718 12.825 5.65115 10.35 4.973C9.45002 5.65115 8.55002 5.65115 7.65002 4.973Z"
                        fill="#E2FF31"/>
                  </svg>
                </div>
                <span class="s1">Команды:</span>
                <span class="s2">
                <span class="s3">{{ TOURNAMENT.attributes.participants_count }}</span> / <span class="s4">{{ TOURNAMENT.attributes.count_of_teams }}</span>
              </span>
              </div>
              <div class="content-registration" v-html="returnTournamentStatus(TOURNAMENT)"></div>
              <button class="filled__yellow" @click="participateInTournament(TOURNAMENT)" v-if="participateButtonStatus">Участвовать в турнире</button>
              <div class="filled__grey decline" @click="declineEntry" v-if="canUserDecline(TOURNAMENT)">Отменить участие</div>
            </div>
          </media>
        </div>
      </div>
    </div>
    <div class="navigation-panel">
      <div class="container">
        <nav class="navigation-panel__wrapper">
          <ul>
            <li>
              <router-link exact-active-class="active" :to="{ name: 'tournaments.info'}">Общее</router-link>
            </li>
            <li>
              <router-link exact-active-class="active" :to="{ name: 'tournaments.teams'}">{{ TOURNAMENT.attributes.participant_type === 'TEAM' ? 'Команды' : 'Игроки' }}</router-link>
              <span>{{TOURNAMENT.attributes.participants_count}}</span>
            </li>
            <li v-if="isNavItemsShowed">
              <router-link active-class="active" :to="{ name: 'tournaments.brackets'}">
                Таблица
              </router-link>
            </li>
            <li v-if="isNavItemsShowed">
              <router-link exact-active-class="active" :to="{ name: 'tournaments.matches'}">Матчи</router-link>
            </li>
            <li>
              <router-link exact-active-class="active" :to="{ name: 'tournaments.rules'}">Правила</router-link>
            </li>
            <li v-if="isWinnersShowed">
              <router-link exact-active-class="active" :to="{name: 'tournaments.winners'}">Победители</router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <transition name="default">
      <router-view/>
    </transition>

    <simple-text-modal v-if="GET_SHOW_THANKS_IS_SIMPLE_MODAL_OPEN"/>
  </div>
  <tournament-page-skeleton v-else/>
</template>

<script>
import Media from "vue-media";
import {mapActions, mapGetters} from 'vuex';
import moment from "moment";
import {tournamentInfoMixin} from "@/otherJS/mixins/tournamentInfoMixin";
import SimpleTextModal from "@/components/modals/simple-text-modal";
import {ActiveEcho as PublicEcho} from "@/main";
import _ from "lodash";
import TournamentPageSkeleton from "@/views/sceleton/tournamentPage-skeleton";

export default {
  name: "tournamentPage",
  components: {TournamentPageSkeleton, SimpleTextModal, Media},
  mixins: [tournamentInfoMixin],
  data() {
    return {
      openTrack: false,
    };
  },

  computed: {
    ...mapGetters([
      'TOURNAMENTS',
      'TOURNAMENT',
      'IS_TOURNAMENTS_IN_REQUEST',
      'PARTICIPANTS',
      'USER',
      'AUTH',
      'USER_MANAGED_TEAMS',
      'USER_CAPTAIN_TEAMS',
      'GET_SHOW_THANKS_IS_SIMPLE_MODAL_OPEN'
    ]),
    toggleActive() {
      return this.openTrack ? 'active' : '';
    },
    isNavItemsShowed() {
      if (moment(this.TOURNAMENT.attributes.started_at).valueOf() < moment().valueOf()) {
        return this.PARTICIPANTS.length !== 0;
      }
      return false;
    },
    isWinnersShowed() {
      return this.TOURNAMENT.attributes.finished_at !== null;
    },
  },
  methods: {
    ...mapActions([
      'GET_TOURNAMENT_BY_ID',
      'GET_MATCHES_RR',
      'GET_MATCHES_PO',
      'CAN_U_GET_DATA_AFTER_USER_AUTH'
    ]),

    async init() {
      await this.GET_TOURNAMENT_BY_ID({id: this.$route.params.id});
      this.currentTournament = this.TOURNAMENT;
      this.dataReady = true;
    },

    /**
     * Обновляем данные по турниру, не чще 1 раза в секунду
     * */
    updateTournament: _.debounce(function () {
      this.GET_TOURNAMENT_BY_ID({id: this.$route.params.id, silently: true});
    }, 60000, {'leading': true, 'trailing': false}),

    /**
     * Подписываемся на ивенты обновления турнира
     * */
    subscribeToTournamentUpdate() {
      PublicEcho.channel(`tournament.${this.$route.params.id}`)
          .listen('.MatchesChanged', ev => {
            this.updateTournament();
          })
          .listen('.TournamentChanged', ev => {
            this.updateTournament();
          })
          .listen('.TournamentIncomingEntriesChanged', ev => {
            this.updateTournament();
          });
    }
  },
  created() {
    if (this.CAN_U_GET_DATA_AFTER_USER_AUTH) {
      this.init();
    }
  },
  mounted() {
    this.subscribeToTournamentUpdate();
  },
  beforeDestroy() {
    PublicEcho.leave(`tournament.${this.$route.params.id}`);
  }
};
</script>

<style scoped lang="scss">
.mt64 {
  margin-top: 64px;
  margin-bottom: 100px;
}
</style>
